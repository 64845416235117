import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import requests from "../../../../../services/requests";

const RankingTableRoute = ({ filterInformation, searchString, searchPeriod }) => {
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);

    const getData = () => {
        requests
            .requestRankingByRoute({
                search: searchString,
                filters: filterInformation,
                period: searchPeriod,
            })
            .then(res => {
                setData(res);
                setLoad(false);
            });
    };

    useEffect(() => {
        getData();
    }, [searchString, filterInformation, searchPeriod]);

    return (
        <>
            {load ? (
                <div className="flex flex-col items-center">
                    <ClipLoader size={20} loading={load} />
                </div>
            ) : (
                <table className="">
                    <thead>
                        <tr>
                            <th>Quantidade de requisições</th>
                            <th>Rota</th>
                            <th>Método</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(row => (
                            <tr>
                                <td>{row?.qtt}</td>
                                <td>{row?.route}</td>
                                <td>{row?.method}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
};

export default RankingTableRoute;
